import React, { useState } from 'react';
import styled from 'styled-components';

import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../../../UI/RoundedBlock/BlockTable/BlockTable';
import Modal from '../../../Modal/Modal';
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/trashicon.svg';
import { Button } from '../../../../shared/styles';

const ViewDocumentsComponent = ({ documents, send, formState }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState({
    url: '',
    id: '',
    name: '',
  });

  const uploadBtnHandler = e => {
    send('UPLOAD_DOCUMENT', { files: Array.from(e.target.files) });
  };

  const downloadClickHandler = (id, name) => {
    send('DOWNLOAD_DOCUMENT', { id, name });
  };

  const deleteDocHandler = (documentID, url, name) => {
    setFileToBeDeleted({ id: documentID, url, name });
    setDeleteModalIsOpen(true);
  };

  const deleteConfirmHandler = () => {
    send('DELETE_DOCUMENT', { fileToBeDeleted });
    setDeleteModalIsOpen(false);
    setFileToBeDeleted({ url: '', id: '', name: '' });
  };
  return (
    <>
      <RoundedBlock
        title="Upload documents"
        btnText="Upload Document"
        fileInputHandler={uploadBtnHandler}
      >
        <Modal
          setModal={setDeleteModalIsOpen}
          open={deleteModalIsOpen}
          onClose={() => {
            setDeleteModalIsOpen(false);
            setFileToBeDeleted({ url: '', id: '', name: '' });
          }}
          modalTitle="Delete Document"
        >
          <DeleteModelContent>
            <h2>Are you sure you want to delete document:</h2>
            <p>{fileToBeDeleted.name}</p>
            <Button type="button" onClick={deleteConfirmHandler}>
              Confirm
            </Button>
          </DeleteModelContent>
        </Modal>
        <BlockTable headers={['FILE NAME', 'ACTIONS']}>
          {documents?.documents?.map(({ id, name, url }) => {
            return (
              <tr key={id}>
                <td className="fileName">{name}</td>
                <td>
                  <div className="button-cont">
                    <button
                      type="button"
                      onClick={() => downloadClickHandler(id, name)}
                    >
                      <ArrowIcon />
                      Download
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteDocHandler(id, url, name)}
                    >
                      <TrashIcon />
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </BlockTable>
      </RoundedBlock>

      {formState.matches('editEvaluationDetails.invalidDocumentType') && (
        <p className="error">Invalid File Type</p>
      )}

      {formState.matches('editEvaluationDetails.duplicateFileName') && (
        <p className="error">
          File with that name already exists. Please rename file or delete
          original file.
        </p>
      )}
    </>
  );
};

export default ViewDocumentsComponent;

const DeleteModelContent = styled.div`
  p {
    margin: 30px;
  }
`;
