import { assign } from 'xstate';
import { useApolloClient } from '@apollo/client';
import posthog from 'posthog-js';

import { returnServiceByType } from '../../shared/functions';

import queries from './queries';
import mutations from './mutations';

function createServices() {
  const client = useApolloClient();

  const { fetchServices, createCart } = queries(client);
  const { createInvoice, voidInvoice } = mutations(client);

  return {
    guards: {},

    services: {
      fetchServices: ctx => fetchServices(ctx.applicationId),

      createCart: ctx =>
        createCart({
          applicationId: ctx.applicationId,
          serviceId: ctx.reviewService.id,
        }),

      createInvoice: ctx =>
        createInvoice({
          applicationId: ctx.applicationId,
          serviceId: ctx.reviewService.id,
        }),

      voidInvoice: ctx =>
        voidInvoice({
          applicationId: ctx.applicationId,
          invoiceId: ctx.finalizedInvoice.id,
        }),
    },

    actions: {
      fetchServicesSuccess: assign({
        reviewService: (_, e) => {
          const services = returnServiceByType(e.data, 'APPLICATION_REVIEW');

          if (services.length > 1) {
            posthog.captureException(
              new Error('Multiple Application Review Services Found'),
            );

            throw new Error('Multiple Application Review Services Found');
          }

          return services[0];
        },
      }),

      createCartSuccess: assign({
        cart: (_, e) => e.data,
      }),

      createInvoiceSuccess: assign({
        finalizedInvoice: (_, e) => e.data.finalized,
        invoiceId: (_, e) => e.data.id,
      }),

      voidInvoiceSuccess: assign({
        finalizedInvoice: () => null,
        cart: () => null,
      }),

      logError: (_, e) => {
        posthog.captureException(new Error(e.data.message));
      },
    },
  };
}

export default createServices;
