import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import posthog from 'posthog-js';
import { Elements } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// import { Button, ButtonsCont } from '../../../shared/styles';

import {
  paymentConfig,
  StyledToastMessage,
} from '../../../shared/notifications/toast';
import InvoiceSummary from './InvoiceSummary';
import getStripe from '../../../shared/stripe';
import PaymentForm from '../../Payment/PaymentForm';
import { CREATE_AND_FINALIZE } from '../../../queries_mutations/CopyRequest/Invoice/mutations';

export default function CopyRequestReviewPayment({ formState, send }) {
  const { applicationId, copyRequestID } = formState.context;

  const [clientSecret, setClientSecret] = useState('');
  const [finalizedInvoice, setFinalizedInvoice] = useState(undefined);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const stripe = getStripe();

  const [createAndFinalize, { loading, data }] =
    useMutation(CREATE_AND_FINALIZE);

  // Create PaymentIntent as soon as the page loads
  useEffect(() => {
    const finalize = async () => {
      try {
        const results = await createAndFinalize({
          variables: {
            applicationId,
            copyRequestId: copyRequestID,
          },
        });

        const finalizedInvoiceResults =
          results.data.copyRequest.invoices.createAndFinalize;

        setFinalizedInvoice(finalizedInvoiceResults.finalized);
        setClientSecret(
          finalizedInvoiceResults.finalized.payment_intent.client_secret,
        );
      } catch (err) {
        posthog.captureException(err);
        Sentry.withScope(scope => {
          scope.setTag('function', 'createAndFinalize');
          scope.setTag('applicationId', applicationId);
          scope.setTag('copyRequestId', copyRequestID);
          Sentry.captureException(err);
        });

        setError(err);
      }
    };

    finalize();
  }, []);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  const onPaid = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Thank you for your payment.</h2>
        <p>Your report request will be processed shortly.</p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('COMPLETE');
    navigate('/dashboard');
  };

  const onCancelled = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Payment was cancelled.</h2>
        <p>
          You're report request was cancelled. You can reconfigure and resubmit.
        </p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('VOID_INVOICE');
  };

  if (error) {
    throw error;
  }

  if (loading)
    return (
      <CopyRequestReviewPaymentStyled>
        <h1>Loading Payment Summary</h1>
        <InvoiceSummary formState={formState} />
        <Skeleton height={600} baseColor="#F7FAFC" />
      </CopyRequestReviewPaymentStyled>
    );

  if (data)
    return (
      <CopyRequestReviewPaymentStyled>
        <h1>Payment Summary</h1>
        <InvoiceSummary formState={formState} />
        <Elements options={options} stripe={stripe}>
          <PaymentForm
            finalizedInvoice={finalizedInvoice}
            onPaid={onPaid}
            onCancelled={onCancelled}
          />
        </Elements>
      </CopyRequestReviewPaymentStyled>
    );
}

const CopyRequestReviewPaymentStyled = styled.div`
  .copyRequest_errormsg {
    color: red;
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 30px;
  }
  .__header {
    background-color: #f7fafc;
  }
  .__content {
    padding: 0;
  }
  .__block {
    display: flex;
    justify-content: left;
    align-items: center;
    box-sizing: content;
    padding: 0 30px;
    background-color: #f7fafc;
    p {
      color: #14527f;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    &.__table-header {
      div {
        border-right: 1px solid #d4d4d4;
        padding: 10px 0;
        flex: 1;
        &:last-child {
          padding-left: 10px;
          border-right: none;
        }
      }
    }
    &.__service {
      align-items: center;
      div {
        flex: 1;
        padding: 10px 0;
        border-right: 1px solid #d4d4d4;
        &:last-child {
          padding-left: 10px;
          border-right: none;
        }
        p {
          color: black;
          font-weight: 400;
        }
      }
      .__address {
        padding-left: 30px;
        border-right: none;
      }
    }
    .__section-header {
      border-right: solid 1px #d4d4d4;
    }
  }
  .__total {
    padding-bottom: 0;

    background-color: #edf2f7;

    h3 {
      color: #14527f;
    }
    div:first-child {
      h3 {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
  }
  .__button-cont {
    margin-top: 30px;
    margin-left: -10px;
  }
`;
