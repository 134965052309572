/* eslint-disable import/prefer-default-export */

function trimIllegalCharacters(input) {
  // const allowedPattern = /^[^\w\s]+|[^\w\s]+$/g;

  // Allow alphanumeric, spaces, underscores, and periods only
  const allowedPattern = /^[^a-zA-Z0-9_ .]+|[^a-zA-Z0-9_ .]+$/g;

  // Replace illegal characters from the start and end of the string
  return input.replace(allowedPattern, '');
}

export const validateUserInput = searchText => {
  return trimIllegalCharacters(searchText);
};
