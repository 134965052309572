/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

import InvoiceDetails from './InvoiceDetails';

export default function InvoiceSummary({ finalizedInvoice, cart }) {
  const { customer_name, account_name, number, description, payment_intent } =
    finalizedInvoice;

  const amount = payment_intent.amount / 100;

  return (
    <PaymentSection>
      <h2>${amount}</h2>
      <p className="subtitle">Payment Due Today</p>

      <SummaryTable>
        <tbody>
          <tr>
            <td className="label">To</td>
            <td>{customer_name}</td>
          </tr>
          <tr>
            <td className="label">From</td>
            <td>{account_name}</td>
          </tr>
          <tr>
            <td className="label">Invoice</td>
            <td>#{number}</td>
          </tr>
          <tr>
            <td className="label">Memo</td>
            <td>{description}</td>
          </tr>
        </tbody>
      </SummaryTable>

      <br />

      <InvoiceDetails cart={cart} />
    </PaymentSection>
  );
}

const PaymentSection = styled.div`
  min-width: 500px;
  padding: 40px 30px 40px 30px;
  align-self: center;
  background-color: #f7fafc;

  border-radius: 4px;

  hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
  .subtitle {
    font-size: 14px;
  }
`;

const SummaryTable = styled.table`
  padding: 2em 0 2em 0;

  font-size: 14px;
  line-height: 1.75;
  .label {
    padding-right: 5vw;
    color: #888;
  }
`;
