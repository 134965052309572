import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

function create(client) {
  function mutate(gql, variables) {
    return client
      .mutate({
        mutation: gql,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          const { message, path } = errors[0];

          Sentry.captureException(
            new Error(`GQL Mutation Call Error: ${message}`, {
              extra: errors,
            }),
          );

          posthog.captureException(
            new Error(`GQL Mutation Call Error: ${message}`),
            {
              path,
            },
          );

          throw new Error('Error Calling GQL Mutation.');
        }

        return data;
      })
      .catch(networkError => {
        Sentry.captureException(
          new Error(`Network error: ${networkError.message}`),
        );

        throw new Error('Network Error Calling GQL Mutation.');
      });
  }

  function query(gql, variables) {
    return client
      .query({
        query: gql,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          const { message, path } = errors[0];

          Sentry.captureException(
            new Error('GQL Query Call Error', { extra: errors }),
          );

          posthog.captureException(
            new Error(`GQL Query Call Error: ${message}`),
            {
              path,
            },
          );

          throw new Error('Error Calling GQL Query.');
        }

        return data;
      })
      .catch(networkError => {
        Sentry.captureException(
          new Error(`Network error: ${networkError.message}`),
        );

        throw new Error('Network Error Calling GQL Query.');
      });
  }

  return { mutate, query };
}

export default create;
