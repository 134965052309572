import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { usePostHog } from 'posthog-js/react';

// import { messages, toast } from '../../shared/notifications';

const ProtectedRoute = ({ children }) => {
  const posthog = usePostHog();
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (user) {
      // if (process.env.NODE_ENV !== 'development')
      //   toast.pop(messages.serviceUnavailable);

      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.sub, {
        email: user.email,
        isAdmin:
          user['https://gceus-api-usmkrvnr3q-uc.a.run.app/roles'].includes(
            'ADMIN',
          ),
      });
    }
  }, [posthog, user?.sub, user?.email]);

  if (!isLoading) {
    // user isn't authenticated and is required to be.
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }

    // user hasn't verified their login email address yet.
    if (user.email_verified === false) return <Navigate to="/verify-email" />;

    return children || <Outlet />;
  }

  return null;
};

export default ProtectedRoute;
