import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMachine } from '@xstate/react';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import ApplicationError from '../../components/Error/ApplicationError';

import {
  paymentConfig,
  StyledToastMessage,
} from '../../shared/notifications/toast';
import { debug } from '../../envConfig';
import Button from '../../components/UI/Button';
import Payment from '../../components/Invoice/Payment';

import createMachine from './machine';

const ApplicationReview = () => {
  const { id } = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();

  const [formState, send] = useMachine(createMachine(id), {
    devTools: debug.xstate,
  });

  const handleOnPay = () => {
    toast.dismiss();
    send('SUBMIT');
  };

  const onPaid = () => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Thank you for your payment.</h2>
        <p>
          Thank you for requesting an application review! Your application will
          be processed shortly.
        </p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('COMPLETED');
    navigate('/dashboard');
  };

  const onCancelled = e => {
    const styledToastMessage = (
      <StyledToastMessage>
        <h2>Your payment was cancelled.</h2>
        <p>Application review payment has been cancelled.</p>
      </StyledToastMessage>
    );

    toast(styledToastMessage, paymentConfig);
    send('CANCELLED', e);
  };

  if (['loadingError', 'paymentError'].some(formState.matches)) {
    return <ApplicationError />;
  }

  if (formState.matches('init')) {
    return <p>Loading...</p>;
  }

  if (formState.matches('waiting')) {
    return (
      <Content>
        <h1>
          <strong>Review After 45 Days</strong>
        </h1>
        <h2>Price: ${formState.context.reviewService.price}.00</h2>
        <p>
          Requests for review of the evaluation, using documents received with
          the original application, must be made in writing within 45 days of
          receiving the report. Requests for review must be submitted in writing
          either via postal service or email. Please include your reference
          number and be very specific in your concerns.
        </p>
        <Button
          title="Pay"
          text="Pay"
          onClick={() => handleOnPay(id, client)}
        />
      </Content>
    );
  }

  if (formState.matches('awaitingPayment')) {
    return (
      <Payment
        cart={formState.context.cart}
        finalizedInvoice={formState.context.finalizedInvoice}
        onPaid={onPaid}
        onCancelled={onCancelled}
      />
    );
  }

  return (
    <>
      <h2 className="title">Loading Payment Summary</h2>
      <Skeleton height={600} baseColor="#F7FAFC" />
    </>
  );
};

export default ApplicationReview;

const Content = styled.div`
  p {
    margin-top: 2rem;
    font-size: 18px;
    max-width: 50rem;
    line-height: 1.5;
  }
  button {
    margin-top: 2rem;
  }
`;
