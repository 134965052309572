import React from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';

function displayLineItem(title, items) {
  const displayItems = [];
  items.forEach(item => {
    for (let i = 0; i < item.quantity; i += 1) {
      displayItems.push({ displayName: item.displayName, price: item.price });
    }
  });

  return (
    <div>
      <div className="content__block">
        <p className="content__block--subTitle">{title}</p>
      </div>

      {displayItems.map(di => (
        <div
          key={Math.random()}
          className="content__block content__block--linedi"
        >
          <p>{di.displayName}</p>
          <p>
            <span>$</span>
            {di?.price}.00
          </p>
        </div>
      ))}
    </div>
  );
}

const PaymentDetails = ({ cart }) => {
  const groupedLineItems = groupBy(cart.lineItems, 'serviceType');

  return (
    <Section>
      <div className="header">
        <h2>Order Information</h2>
      </div>

      <div className="content">
        <div className="content__block content__block--header">
          <p>Services</p>
          <p>Price</p>
        </div>

        {/* APPLICATION SERVICE */}
        {groupedLineItems.APPLICATION_SERVICE &&
          displayLineItem(
            'Evaluation Service',
            groupedLineItems.APPLICATION_SERVICE,
          )}

        {/* RUSH SERVICE */}
        {groupedLineItems.RUSH &&
          displayLineItem('Rush Service', groupedLineItems.RUSH)}

        {/* RETURN DOCUMENTS */}
        {groupedLineItems.RETURNING_DOCUMENTS &&
          displayLineItem(
            'Return Documents',
            groupedLineItems.RETURNING_DOCUMENTS,
          )}

        {/* ADDITIONAL REPORTS MAILED */}
        {groupedLineItems.ADDITIONAL_REPORT_SHIPPING &&
          displayLineItem(
            `Additional ${
              groupedLineItems.ADDITIONAL_REPORT_SHIPPING.length > 1
                ? 'Addresses'
                : 'Address'
            }
              (${groupedLineItems.ADDITIONAL_REPORT_SHIPPING.length} added)
              `,

            groupedLineItems.ADDITIONAL_REPORT_SHIPPING,
          )}

        {/* ADDITIONAL EMAILED REPORTS */}
        {groupedLineItems.ADDITIONAL_EMAIL &&
          displayLineItem(
            `Additional Email ${
              groupedLineItems.ADDITIONAL_EMAIL[0].quantity > 1
                ? 'Reports'
                : 'Report'
            }
              (${groupedLineItems.ADDITIONAL_EMAIL[0].quantity} added)
              `,

            groupedLineItems.ADDITIONAL_EMAIL,
          )}

        {/* todo: @theClarkSell we don't know the email address here to display it.. it's just a summary. but this is on the previous page */}
        {/* todo: @WadeDrift proposal we just remove this block */}

        {/* {groupedLineItems.ADDITIONAL_EMAIL?.length > 0 && (
          <>
            <div className="content__block">
              <p>
                Additional Email{' '}
                {groupedLineItems.ADDITIONAL_EMAIL.length > 1
                  ? 'Reports'
                  : 'Report'}
                :
              </p>
            </div>
            {groupedLineItems.ADDITIONAL_EMAIL?.map(({ email }) => {
              return (
                <div key={Math.random()} className="content__block">
                  <p>{email}</p>
                  <p>
                    <span>$</span>
                    {emailService.price}.00
                  </p>
                </div>
              );
            })}
          </>
        )} */}

        <div className="content__block content__block--total">
          <p>Total Amount:</p>
          <p>
            <span>$</span>
            {cart.total}.00
          </p>
        </div>
      </div>
    </Section>
  );
};

export default PaymentDetails;

const Section = styled.div`
  background-color: #f7fafc;
  border-radius: 2px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 25px;
    border-bottom: solid 1px #d4d4d4;
    h2 {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .content {
    &__block {
      display: grid;
      grid-template-columns: 3fr 1fr;
      justify-content: left;
      padding: 13px 30px;

      &--header {
        p {
          color: #14527f;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      &--subTitle {
        grid-column: span 2;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      &--lineItem {
        font-size: 14px;
        margin-left: 20px;
        border-top: solid 1px #d4d4d4;
      }

      &--total {
        margin-top: 20px;
        background-color: #edf2f7;
        p {
          text-transform: uppercase;
          font-weight: 700;
          &:first-child {
            color: #14527f;
          }
        }
      }
    }
  }
`;
