import React from 'react';
import styled from 'styled-components';

import Button from '../UI/Button';

const Content = styled.div`
  p {
    margin-top: 2rem;
    font-size: 18px;
    max-width: 50rem;
    line-height: 1.5;
  }
  button {
    margin-top: 2rem;
  }
`;

const ApplicationError = () => {
  return (
    <Content>
      <h2>We’re Sorry, Something Went Wrong</h2>
      <p>
        Well that didn't go as planned. We've logged the error and notified the
        team who will look into it.
      </p>
      <p>
        In the meantime, please refresh the page and try again. If this error
        persists, please <a href="/contact">contact us</a>.
      </p>

      <Button
        title="Refresh"
        text="Refresh Page"
        // eslint-disable-next-line no-restricted-globals
        onClick={() => location.reload()}
      />
    </Content>
  );
};

export default ApplicationError;
