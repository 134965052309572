import { gql } from '@apollo/client';

export const CREATE_DOC_URL = gql`
  mutation CreateUploadUrlMutation(
    $fullPathName: String!
    $contentType: String!
    $applicationId: String!
  ) {
    applications {
      me {
        application(id: $applicationId) {
          appDocumentsInfo {
            createUploadUrl(
              fullPathName: $fullPathName
              contentType: $contentType
            )
          }
        }
      }
    }
  }
`;

export const SAVE_DOC_URL = gql`
  mutation saveDocUrlMutation(
    $document: ApplicationDocumentCreateInput!
    $applicationId: String!
  ) {
    applications {
      me {
        application(id: $applicationId) {
          appDocumentsInfo {
            document {
              addDocument(document: $document) {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_DOC_URL = gql`
  mutation deleteDocUrlMutation(
    $applicationId: String!
    $document: DeleteDocumentInput!
  ) {
    applications {
      me {
        application(id: $applicationId) {
          appDocumentsInfo {
            document {
              removeDocument(document: $document) {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default client => {
  const createDocumentUploadUrl = ({ variables }) => {
    return client
      .mutate({
        mutation: CREATE_DOC_URL,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw new Error();
        }

        return data;
      });
  };

  const saveURLToDB = ({ variables }) => {
    return client
      .mutate({
        mutation: SAVE_DOC_URL,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw new Error(errors[0].message);
        }

        return data;
      });
  };

  const deleteDocument = ({ variables }) => {
    return client
      .mutate({
        mutation: DELETE_DOC_URL,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw new Error();
        }

        return data;
      });
  };

  return {
    createDocumentUploadUrl,
    saveURLToDB,
    deleteDocument,
  };
};
